<template>
  <div class="custom-table" id="dynamic-products-id">
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'DynamicProducts'">
      <template v-slot:right-end>
        <div class="vx-row">
          <vs-button
            class="alpha-clear"
            v-if="selectedAlpha"
            color="warning"
            type="flat"
            icon-pack="feather"
            icon="icon-x"
            size="small"
            @click="clearSelectedAlpha()"
          ></vs-button>
          <vs-pagination-modified
            v-model="selectedAlphaCurrentX"
            :total="26"
            :max="26"
            v-on:change="pageChanged"
          ></vs-pagination-modified>
        </div>
      </template>
    </breadcrumb-base>
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
        >
          <img
            src="@/assets/images/pages/svg_error.svg"
            :alt="$t('NoData')"
            class="mx-auto mb-4 max-w-full"
          />
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t("ErrorData") }}</h1>
        </div>
      </div>
    </transition>
    <vs-table-modified v-show="!errorFetching" ref="table" :data="dynamicProducts" :max-items="50">
      <template slot="header">
        <vs-row>
          <vs-select
            autocomplete
            v-model="selectedProductType"
            class="select-large"
            :label="$t('ProductType')"
            @input="onProductTypeSelected"
          >
            <vs-select-item :value="null" :text="$t('All')" />
            <vs-select-item
              :key="index.key"
              :value="item.key"
              :text="item.name"
              v-for="(item, index) in productTypes"
            />
          </vs-select>
          <vs-spacer></vs-spacer>

          <div class="con-input-search vs-table--search pr-5">
            <vs-input
              class="input-search"
              @input="searchDebounce"
              v-model="searchText"
              type="text"
              icon-no-border
              icon="search"
            />
          </div>
          <div>
            <vs-button @click="onSubmitProducts" type="filled"
              >{{
                $t("Submit") + "  " + (selectedConfigs.length > 0 ? selectedConfigs.length : "")
              }}
            </vs-button>
          </div>
        </vs-row>
      </template>

      <template slot="thead">
        <vs-th></vs-th>
        <vs-th>{{ $t("ProductName") }}</vs-th>
        <vs-th>{{ $t("Image") }}</vs-th>
        <vs-th>{{ $t("Denomination") }}</vs-th>
        <vs-th v-if="isNotGiftCard">{{ $t("AutoCashId") }}</vs-th>
        <vs-th v-if="isNotGiftCard">{{ $t("AutoCashDiscount") }}</vs-th>
        <vs-th>{{ $t("EPayApiEAN") }}</vs-th>
        <vs-th>{{ $t("Services") }}</vs-th>
        <vs-th>{{ $t("EpayAPIDiscount") }}</vs-th>
        <!--        <vs-th>{{$t('EPayApiName')}}</vs-th>-->
        <!-- Adding new columns for the content card pin API fields -->
        <vs-th>{{ $t("ContentCardPinAPIID") }}</vs-th>
        <vs-th>{{ $t("ContentCardPinAPIDiscount") }}</vs-th>
        <vs-th v-if="isNotGiftCard">{{ $t("Priority") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="tr.id" v-for="(tr, indextr) in data">
            <vs-td>
              <vs-checkbox
                v-model="dummyChecks[tr.product_configuration_id]"
                @input="onProductChecked"
                :vs-value="tr.product_configuration_id.toString()"
              ></vs-checkbox>
            </vs-td>

            <vs-td>
              <p class="" style="font-weight: bold">{{ tr.product_name }}</p>
            </vs-td>
            <vs-td>
              <img
                :src="'https://www.pjtelesoftgmbh.de/' + tr.product_image"
                :alt="tr.product_name"
                class="product-image"
              />
            </vs-td>

            <vs-td>
              <p class="" style="font-weight: bold">
                {{ tr.product_configuration_value | germanNumberFormat }}
              </p>
            </vs-td>

            <vs-td v-if="isNotGiftCard">
              <vs-input
                style="width: 170px"
                :disabled="!checkIsChecked(tr.product_configuration_id)"
                class="vs-inputx inputx"
                :placeholder="$t('AutoCashId')"
                v-model="updatedCardsAutoCashAPI[tr.product_configuration_id]"
              />
            </vs-td>

            <vs-td>
              <vs-input
                style="width: 100px"
                :disabled="!checkIsChecked(tr.product_configuration_id)"
                class="vs-inputx inputx"
                :placeholder="$t('Discount')"
                v-model="updatedCardsAutoCashDiscount[tr.product_configuration_id]"
              />
            </vs-td>

            <vs-td>
              <vs-input
                style="width: 170px"
                :disabled="!checkIsChecked(tr.product_configuration_id)"
                class="vs-inputx inputx"
                :placeholder="$t('EPayApiEAN')"
                v-model="updatedCardsEPayAPI[tr.product_configuration_id]"
                @input="onEPayApiIdEdited($event, tr.product_configuration_id)"
              />
            </vs-td>

            <vs-td>
              <div class="flex flex-row">
                <select
                  :disabled="!checkIsChecked(tr.product_configuration_id)"
                  class="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  style="width: 100px; -webkit-appearance: listbox !important"
                  v-model="updatedTerminalPool[tr.product_configuration_id]"
                  aria-label=""
                >
                  <option hidden disabled selected value></option>
                  <option
                    :value="item.id"
                    :key="index + '_' + tr.product_configuration_id"
                    v-for="(item, index) in dynamicProductsRaw.terminal_pools"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <vs-button
                  class="alpha-clear"
                  v-if="
                    updatedTerminalPool[tr.product_configuration_id] &&
                    checkIsChecked(tr.product_configuration_id)
                  "
                  color="danger"
                  type="flat"
                  icon-pack="feather"
                  icon="icon-x"
                  size="small"
                  @click="updatedTerminalPool[tr.product_configuration_id] = null"
                ></vs-button>
              </div>
              <!--            <vs-select-->
              <!--              v-if="dynamicProductsRaw && dynamicProductsRaw.terminal_pools"-->
              <!--              style="width: 100px;"-->
              <!--              label=""-->
              <!--              v-model="updatedTerminalPool[tr.product_configuration_id]"-->
              <!--              :disabled="!checkIsChecked(tr.product_configuration_id)"-->
              <!--            >-->
              <!--              <vs-select-item :value="item.id" :text="item.name" :key="index + '_' + tr.product_configuration_id" v-for="(item, index) in dynamicProductsRaw.terminal_pools" />-->
              <!--            </vs-select>-->
            </vs-td>
            <vs-td>
              <vs-input
                style="width: 100px"
                :disabled="!checkIsChecked(tr.product_configuration_id)"
                class="vs-inputx inputx"
                :placeholder="$t('Discount')"
                v-model="updatedCardsEPayDiscount[tr.product_configuration_id]"
              />
            </vs-td>

            <!--          <vs-td>-->
            <!--            <vs-input :disabled="!checkIsChecked(tr.product_configuration_id)" class="vs-inputx inputx" :placeholder="$t('EPayApiName')" v-model="updatedCardsEPayName[tr.product_configuration_id]" @input="onEPayApiNameEdited($event, tr.product_configuration_id)"/>-->
            <!--          </vs-td>-->
            <!-- Adding new td elements for the content card pin API fields -->
            <vs-td>
              <vs-input
                style="width: 170px"
                :disabled="!checkIsChecked(tr.product_configuration_id)"
                class="vs-inputx inputx"
                :placeholder="$t('ContentCardPinAPIID')"
                v-model="updatedContentCardPinAPIID[tr.product_configuration_id]"
                @input="onContentCardPinAPIIDEdited($event, tr.product_configuration_id)"
              />
            </vs-td>

            <vs-td>
              <vs-input
                style="width: 100px"
                :disabled="!checkIsChecked(tr.product_configuration_id)"
                class="vs-inputx inputx"
                :placeholder="$t('Discount')"
                v-model="updatedContentCardPinAPIDiscount[tr.product_configuration_id]"
                @input="onContentCardPinAPIDiscountEdited($event, tr.product_configuration_id)"
              />
            </vs-td>
            <vs-td v-if="isNotGiftCard">
              <vs-select
                style="width: 100px"
                autocomplete
                label=""
                v-model="updatedPriority[tr.product_configuration_id]"
                :disabled="!checkIsChecked(tr.product_configuration_id)"
              >
                <vs-select-item :value="-1" text="None" />
                <vs-select-item :value="2" text="AutoCash" />
                <vs-select-item :value="1" text="EPay" />
                <vs-select-item :value="3" text="ContentCard" />
              </vs-select>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>

      <template slot="footer"> </template>
      <template slot="footer-left">
        <div class="flex">
          <vs-new-pagination
            :total="vTotalPages"
            v-model="currentPage"
            v-on:change="listPageChanged"
          >
          </vs-new-pagination>
        </div>
      </template>
    </vs-table-modified>
  </div>
</template>

<script>
import VxModal from "@/layouts/components/custom/VxModal";

export default {
  name: "dynamic_products",
  components: {
    VxModal,
  },
  data() {
    return {
      currentPage: 1,
      dummyChecks: {},
      isMounted: false,
      errorFetching: false,
      selectedConfigs: [],
      updatedCardsEPayAPI: {},
      updatedCardsAutoCashAPI: {},
      updatedTerminalPool: {},
      updatedCardsEPayDiscount: {},
      updatedCardsAutoCashDiscount: {},
      updatedPriority: {},
      searchText: "",
      selectedAlpha: null,
      selectedAlphaCurrentX: 1,
      breadcrumb: [
        { title: "Home", i18n: "Home", url: "/" },
        { title: "Dynamic Products", i18n: "DynamicProducts", active: true },
      ],
      selectedProductType: null,
      productTypes: [
        {
          name: "Cash Cards",
          key: "cash card",
        },
        {
          name: "Gift Cards",
          key: "gift card",
        },
        {
          name: "Calling Cards",
          key: "calling card",
        },
      ],
      // content_card_pin_api_id: {},
      // content_card_pin_api_discount: {},
      // Content Card PIN API
      updatedContentCardPinAPIID: {},
      updatedContentCardPinAPIDiscount: {},
    };
  },
  computed: {
    isNotGiftCard() {
      return this.selectedProductType !== "gift card";
    },
    dynamicProducts() {
      return this.$store.state.dynamicProducts.dynamicProducts;
    },
    dynamicProductsRaw() {
      return this.$store.state.dynamicProducts.dynamicProductsRaw;
    },
    vPage() {
      return this.$store.state.dynamicProducts.vPage;
    },
    vLimit() {
      return this.$store.state.dynamicProducts.limit;
    },
    vTotalPages() {
      return this.$store.state.dynamicProducts.vTotalPages;
    },
  },
  methods: {
    clearSelectedAlpha() {
      this.selectedAlpha = null;
      this.selectedAlphaCurrentX = 1;

      this.searchText = "";
      this.fetchDynamicProducts("0", this.searchText);
    },
    pageChanged(newIndex) {
      if (isNaN(newIndex)) {
        this.selectedAlpha = newIndex;
        this.selectedAlphaCurrentX = newIndex;
        this.searchText = this.selectedAlphaCurrentX;
      }
      this.fetchDynamicProducts("0", this.searchText);
    },
    onProductTypeSelected() {
      this.selectedAlpha = null;
      this.selectedAlphaCurrentX = 1;
      this.searchText = "";
      this.fetchDynamicProducts();
    },
    onSubmitProducts() {
      if (this.selectedConfigs.length === 0) {
        this.$vs.notify({
          position: "top-center",
          title: "Error",
          text: "Please select at-least one product",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return;
      }
      const d = [];
      this.selectedConfigs.forEach((p) => {
        const autoCashId = this.updatedCardsAutoCashAPI[p];
        const epayId = this.updatedCardsEPayAPI[p];
        const terminalPoolId = this.updatedTerminalPool[p];
        const epayDiscount = this.updatedCardsEPayDiscount[p];
        const autocashDiscount = this.updatedCardsAutoCashDiscount[p];
        const epayName = this.updatedCardsEPayAPI[p];
        const contentCardPinApiId = this.updatedContentCardPinAPIID[p];
        const contentCardPinApiDiscount = this.updatedContentCardPinAPIDiscount[p];
        const priority = this.updatedPriority[p];
        const obj = {
          product_configuration_id: p,
          auto_cash_id: autoCashId,
          epay_api_id: epayId,
          epay_api_name: epayName,
          epay_api_discount: epayDiscount,
          auto_cash_discount: autocashDiscount === "" ? 0 : autocashDiscount,
          terminal_pool_id: terminalPoolId || "remove",
          content_card_pin_api_id: contentCardPinApiId,
          content_card_pin_api_discount:
            contentCardPinApiDiscount === "" ? 0 : contentCardPinApiDiscount,
        };
        if (priority == -1) {
          obj["remove_priority"] = 1;
        } else {
          obj["priority"] = priority;
        }

        d.push(obj);
      });

      this.$vs.loading();
      this.$store
        .dispatch("dynamicProducts/updateDynamicProductConfigs", d)
        .then((data) => {
          this.$vs.loading.close();
          this.errorFetching = false;
          this.$vs.notify({
            position: "top-center",
            title: "Success",
            text: "Products Updated Successful",
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success",
          });
          this.initValues();
          this.updateData();
          // Reset the currentPage to 1 after data update
          this.currentPage = 1
          
          // Fetch dynamic products with updated pagination state
          this.fetchDynamicProducts();
        })
        .catch((error) => {
          console.error(error);
          this.$vs.loading.close();
          this.errorFetching = true;
          this.$vs.notify({
            position: "top-center",
            title: "Error",
            text: error.response.data.message || error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    checkIsChecked(id) {
      return this.selectedConfigs.indexOf(id.toString()) !== -1;
    },
    onProductChecked(value) {
      this.selectedConfigs = [];
      Object.values(this.dummyChecks).forEach((d) => {
        if (d) this.selectedConfigs.push(d);
      });
    },
    onEPayApiIdEdited(event, id) {},
    onEPayApiNameEdited(event, id) {},
    onContentCardPinAPIIDEdited(event, id) {
      // Handle the input event for the content_card_pin_api_id field
    },
    onContentCardPinAPIDiscountEdited(event, id) {
      // Handle the input event for the content_card_pin_api_discount field
    },
    initValues() {
      this.dummyChecks = {};
      this.updatedCardsAutoCashAPI = {};
      this.selectedProductType = null;
      this.updatedCardsEPayAPI = {};
      this.updatedCardsEPayDiscount = {};
      this.updatedCardsAutoCashDiscount = {};
      this.updatedTerminalPool = {};
      this.updatedPriority = {};
      this.selectedConfigs = [];
      this.updatedContentCardPinAPIID = {};
      this.updatedContentCardPinAPIDiscount = {};
    },
    updateData() {
      const epayApi = {};
      const epayDiscount = {};
      const autocashDiscount = {};
      const autoCashAPI = {};
      const terminalPools = {};
      const upP = {};
      const contentCardPinApiId = {};
      const contentCardPinApiDiscount = {};
      this.dynamicProducts.forEach((p) => {
        // console.log("Dynamic products:", p);
        if (p.epay_api_id) epayApi[p.product_configuration_id] = p.epay_api_id;
        if (p.epay_api_discount) epayDiscount[p.product_configuration_id] = p.epay_api_discount;
        if (p.content_card_pin_api_id !== null && p.content_card_pin_api_id !== undefined) {
          contentCardPinApiId[p.product_configuration_id] = p.content_card_pin_api_id;
        }
        if (
          p.content_card_pin_api_discount !== null &&
          p.content_card_pin_api_discount !== undefined
        ) {
          contentCardPinApiDiscount[p.product_configuration_id] = p.content_card_pin_api_discount;
        }
        // else {
        //   contentCardPinApiDiscount[p.product_configuration_id] = 0;
        // }
        if (p.auto_cash_discount)
          autocashDiscount[p.product_configuration_id] = p.auto_cash_discount;
        if (p.auto_cash_id) autoCashAPI[p.product_configuration_id] = p.auto_cash_id;
        if (p.terminal_pool_id) terminalPools[p.product_configuration_id] = p.terminal_pool_id;
        if (p.priority != null) upP[p.product_configuration_id] = p.priority;
        else upP[p.product_configuration_id] = null;
      });

      this.updatedCardsAutoCashAPI = autoCashAPI;
      this.updatedCardsEPayAPI = epayApi;
      this.updatedTerminalPool = terminalPools;
      this.updatedCardsEPayDiscount = epayDiscount;
      this.updatedCardsAutoCashDiscount = autocashDiscount;
      this.updatedPriority = upP;
      this.updatedContentCardPinAPIID = contentCardPinApiId;
      this.updatedContentCardPinAPIDiscount = contentCardPinApiDiscount;
    },

    searchDebounce() {
      clearTimeout(this.interval);
      this.interval = setTimeout(() => {
        if (this.searchText !== this.selectedAlpha) {
          this.selectedAlpha = null;
          this.selectedAlphaCurrentX = 1;
        }
        this.fetchDynamicProducts("0", this.searchText);
      }, 500);
    },
    listPageChanged(item) {
      this.fetchDynamicProducts(this.currentPage - 1, this.searchText);
    },
    fetchDynamicProducts(page = "0", search = "") {
      this.$vs.loading();
      return this.$store
        .dispatch("dynamicProducts/fetchDynamicProductConfigs", {
          data: this.selectedProductType,
          page,
          search,
        })
        .then((data) => {
          this.$vs.loading.close();
          this.errorFetching = false;
          this.updateData();
        })
        .catch((error) => {
          console.error(error);
          this.$vs.loading.close();
          this.errorFetching = true;
          this.$vs.notify({
            position: "top-center",
            title: "Error",
            text: error.message ? error.message : error.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
  },
  created() {
    this.$vs.loading();
  },
};
</script>

<style lang="scss">
#dynamic-products-id {
  .vs-input--input {
    background-color: #ffffff !important;
    opacity: 1 !important;
    font-weight: bold;
  }
  .vs-select--input {
    background-color: #ffffff !important;
    opacity: 1 !important;
    font-weight: bold;
  }
  .vs-input--input:disabled {
    background-color: #ffffff !important;
    opacity: 1 !important;
    font-weight: bold;
  }
  .vs-select--input:disabled {
    background-color: #ffffff !important;
    opacity: 1 !important;
    font-weight: bold;
  }
}
</style>
